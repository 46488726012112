<template lang="pug">
div
  page-header(:title='title')
  form(@submit.prevent='gravar')
    .modal-body
      .form-horizontal
        vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='obs')  Chamado
          .col-sm-9
            textarea.form-control.autosize(v-model='form.texto', placeholder='Chamado')
        .form-group
          select-form(v-model='form.status_id', :value='form.status_id', name='Status', :url='`${user.id == 1 ? "/chamado/status" : "/chamado/status/1"}`', id='id', titulo='titulo', layout='true', optionText='Selecione', required='true')
    footer-button(:disabled='isActive')
</template>

<script>
import { mapGetters } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import SelectForm from '@/components/form/SelectForm'
import req from '@/components/request'
import { sucesso, erro } from '@/components/Message'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    SelectForm,
    VueElementLoading
  },
  data() {
    return {
      title: 'Chamados',
      form: {
        texto: '',
        status_id: ''
      },
      isActive: false
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    gravar() {
      const body = this.form
      if (this.$route.params.id) {
        req(`/chamado/${this.$route.params.id}`, 'put', body)
          .then(() => {
            sucesso('Atualizado...')
            this.$router.push('/inicio/chamados')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      } else {
        req('/chamado', 'post', body)
          .then(() => {
            sucesso('Atualizado...')
            this.$router.push('/inicio/chamados')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      }
    },
    getDados() {
      req(`/chamado/${this.$route.params.id}`)
        .then(resp => {
          this.form.status_id = resp.status_id
          this.form.texto = resp.texto
          this.isActive = false
        })
        .catch(response => {
          console.log('error: ' + response)
          erro()
        })
    },
    verifPermissao() {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
